import { AgGridVue } from 'ag-grid-vue';
import enums from '@/enums';
import { mapGetters } from 'vuex';

/**
 * Provide common functionality to use AgGrid table
 * @mixin views/modules/mixins/commonAgGrid
 *
 * @vue-prop {string} agGridOverlayId - id to ag grid overlay
 * @vue-prop {boolean} [hideActionsColumn=false] - indicate if hide or no the actions column
 * @vue-prop {boolean} [hideTimestampsColumns=false] - indicate if hide or no the timestamps columns
 * @vue-prop {boolean} [hideCreatedAtColumn=false] - indicate if hide or no the created at column
 * @vue-prop {boolean} [hideUpdatedAtColumn=false] - indicate if hide or no the updated at column
 * @vue-prop {string} [createdAtHeaderName='$General.CreatedAt'] - created at header name
 * @vue-prop {string} [updatedAtHeaderName='$General.UpdatedAt'] - updated at header name
 * @vue-prop {Object} initialFilters - initial filters to apply in the ag grid table
 * @vue-prop {Object} initialFilterMatch - initial filters match to apply in the ag grid table
 * @vue-prop {number} actionsMinWidth - min with for action buttons
 * @vue-prop {number} actionsMaxWidth - max with for action buttons
 * @vue-data {string} dataModel - Type of data model to list, could be server or client side
 * @vue-data {number} count - count of total rows in the collection
 * @vue-data {number} agGridKey - key to restart ag grid component
 * @vue-data {Function | null} afterOnModelUpdateFunction - function to call after modal update
 * @vue-data {boolean} selectionPageAlert - indicate if show or no the selection page alert
 * @vue-data {boolean} firstGetRows - indicate if the get row request is the first
 * @vue-data {number} filtersAppliedCount - count of filter applied
 * @vue-data {number} maxConcurrentDatasourceRequests - max concurrent request to data source
 * @vue-data {number} cacheBlockSize - block size to cache
 * @vue-data {number} maxBlocksInCache - max number of blocks in cache
 * @vue-data {number} defaultPaginationPageSize - default size for pages
 * @vue-data {string} [filtersMatch='...'] - indicate if all filter or any filter must match
 * @vue-data {string} overlayLoadingTemplate - overlay loading template
 * @vue-data {Object} dataSource - data source to get rows
 * @vue-data {boolean} [setFiltersBulkOperation=false] - indicate is set bulk filter was made
 * @vue-data {number} [setFiltersBulkOperationAmount=0] - number of filters setter on set bulk
 * @vue-data {number} [setFiltersBulkOperationCount=0] - count of filter applied of set bulk
 * @vue-data {Array.<Object>} [columnDefs=[]] - columns of ag grid table
 * @vue-data {Array.<Object>} [rowsSelected=[]] - rows selected in any time
 * @vue-data {boolean} [selectedAllRows=false] - indicate if all row were selected
 * @vue-data {number} [getRowNodeId=null] - row node id for ad grid rows
 * @vue-data {string} [searchQuery=''] - text to search in the ag grid table
 * @vue-data {Object} [gridApi=null] - ag grid api
 * @vue-data {Object} [gridColumnApi=null] - ag grid column api
 * @vue-data {Object} [gridOptions=Object] - option to ag grid table
 * @vue-data {Object} [columnTypes=Object] - column type to use
 * @vue-data {Object} [defaultColDef=Object] - default common column attributes on ag grid table
 * @vue-data {Array} [commonColumnDefs=[]] - common columns of ag grid table
 * @vue-data {string} [lastSavedId=null] - last saved id row
 * @vue-data {string[]} [lastSavedIds=[]] - last saved ids rows
 * @vue-data {boolean} [showOnlyFirstColumnOnMobile=true] -
 * indicate if show only first column on mobile
 * @vue-computed {number} paginationPageSize - rows per page on ag grid table
 * @vue-computed {number} totalPages - number of pages on ag grid table
 * @vue-computed {number} currentPage - get and set current page on ag grid table
 * @vue-computed {string} domLayout - grid dom layout
 * @vue-computed {string} gridStyle - grid style
 * @vue-computed {boolean} hasPagination - indicate if grid has pagination
 * @vue-computed {boolean} suppressPaginationPanel - indicate if grid suppress pagination panel
 * @vue-computed {boolean} suppressColumnVirtualisation -
 * indicate if supress column virtualisation or no
 * @vue-computed {Array.<Object>} getColumnsToExport - columns available to export on ag grid table
 * @vue-computed {Array.<Object>} columnDefsToImport - columns definition to import
 * @vue-computed {number} rowsSelectedCount - number of rows selected on ag grid table
 * @vue-computed {number} rowsSelectedCountWithAllSelection -
 * number of rows selected on ag grid table
 * taking into account if everything was selected but not all the rows are in the table
 * @vue-computed {boolean} isAnyRowsSelected - true is any row is selected on ag grid table
 * @vue-computed {boolean} isAnyRowsSelectedOrAll - true is any row or all selected otherwise false
 * @vue-computed {boolean} rowsSelectedAreReallyAll - indicate if all rows are really selected
 * @vue-computed {Object[] | null} rowsSelectedWithAllSelectionOrNull -
 * rows selected or null if everything was selected but not all the rows are in the table
 * @vue-event {void} $mq - watch called on windows resolution changed
 * @vue-event {void} beforeMount - hook to prepare the columns definition,
 * value getter action definition and get row node id before load ag grid api
 * @vue-event {void} mounted - hook to load ag grid api and before mount
 * @vue-event {void} beforeDestroy - hook clean resource
 * @vue-event {void} onGridReady - called on grid ready to set datasource
 * @vue-event {void} onModelUpdate - called on model updated
 * @vue-event {void} onFilterChanged - called on filter changed
 * @vue-event {void} onFirstDataRendered - called on first data renderer
 * @vue-event {void} onGridSizeChanged - called in grid size changed
 * @vue-event {void} setAgGridDataSource - set ag grid datasource
 * @vue-event {void} updateSearchQuery - set quick filter for searchQuery string
 * @vue-event {void} onSelectionChanged - called on selection rows changed to update rowsSelected
 * @vue-event {void} afterOnSelectionChanged - called after on selection rows changed
 * @vue-event {void} loadGridApi - load the ag grid api and ag grid column api interfaces
 * @vue-event {Promise<Object>} fetchAgGridData - fetch data to ag grid table
 * @vue-event {void} fitColumnsOnChanges - fit columns on changes
 * @vue-event {void} remainsSpaceInViewport - indicate if exist remain space in viewport
 * @vue-event {void} autoSizeOrFitColumnsByViewport -
 * auto size or fit all columns on ag grid table by viewport space
 * @vue-event {void} autoSizeColumns - auto size all columns on ag grid table
 * @vue-event {void} rowDataUpdated - called on ag grid row data updated
 * @vue-event {void} resetDataSourceAndSelection - reset the datasource and selection
 * @vue-event {void} getMappedFilterModel - mapped filter model
 * @vue-event {void} getSortModel - return the sort model
 * @vue-event {void} applyFilters - apply filters
 * @vue-event {void} resetFilters - reset the filters
 * @vue-event {void} getFiltersBulkOperationAmount - get number of filter to apply in set bulk
 * @vue-event {void} paginationSetPageSize - set pagination page size
 * @vue-event {void} setSelectedRowsAll - select all rows
 * @vue-event {void} setSelectedRowsCurrentPage - select rows of current page
 * @vue-event {void} currentPageIsSelected - indicate if current page is selected
 * @vue-event {void} setSelectedRowsNone - deselect all rows
 * @vue-event {void} selectFromHeader - make selection from header
 * @vue-event {void} deleteColumnDef - delete one column of col def by id
 * @vue-event {void} deleteCommonColumnDef - delete one column of common col def by id
 * @vue-event {void} refreshRowNodeDataById - refresh row node data by row node id
 */
export default {
  components: {
    AgGridVue,
  },
  props: {
    agGridOverlayId: {
      type: String,
      required: false,
      default() {
        const random = Math.random().toString(36).substring(7);
        return `agGridOverlay-${random}`;
      },
    },
    hideActionsColumn: {
      type: Boolean,
      default: false,
    },
    hideTimestampsColumns: {
      type: Boolean,
      default: false,
    },
    hideCreatedAtColumn: {
      type: Boolean,
      default: false,
    },
    hideUpdatedAtColumn: {
      type: Boolean,
      default: false,
    },
    createdAtHeaderName: {
      type: String,
      default: '$General.CreatedAt',
    },
    updatedAtHeaderName: {
      type: String,
      default: '$General.UpdatedAt',
    },
    initialFilters: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    initialFilterMatch: {
      type: String,
      required: false,
      default: enums.AppFilterMathType.ALL,
    },
    actionsMinWidth: {
      type: Number,
      required: false,
      default: 150,
    },
    actionsMaxWidth: {
      type: Number,
      required: false,
      default: 400,
    },
    readonlyList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dataModel: this.$enums.DataModel.SERVER,
      count: 0,
      agGridKey: 0,
      afterOnModelUpdateFunction: null,
      selectionPageAlert: false,
      firstGetRows: true,
      filtersAppliedCount: 0,
      maxConcurrentDatasourceRequests: 1,
      cacheBlockSize: 100,
      currentPage: 1,
      maxBlocksInCache: 1,
      defaultPaginationPageSize: 10,
      filtersMatch: this.initialFilterMatch,
      overlayLoadingTemplate: `<div class="ag-overlay-loading-center"
          style="box-shadow: none; background: transparent; width: 100px; height: 100px">
          <div id="${this.agGridOverlayId}"
            class="vs-con-loading__container"
            style="background: transparent; width: 100%; height: 100%">
          </div>
        </div>`,
      dataSource: {
        async getRows(params) {
          const { componentParent } = params.context;
          const overlayId = `#${componentParent.agGridOverlayId}`;

          if (componentParent.setFiltersBulkOperation) {
            componentParent.setFiltersBulkOperationCount += 1;
          }

          if (componentParent.setFiltersBulkOperation
            && componentParent.setFiltersBulkOperationCount
            < componentParent.setFiltersBulkOperationAmount) {
            return;
          }

          if (componentParent.setFiltersBulkOperation) {
            componentParent.setFiltersBulkOperation = false;
            componentParent.setFiltersBulkOperationCount = 0;
            componentParent.setFiltersBulkOperationAmount = 0;
          }

          componentParent.gridApi.showLoadingOverlay();
          componentParent.$vs.loading({
            container: overlayId,
            scale: 0.6,
          });

          const filters = componentParent.getMappedFilterModel();
          const data = await componentParent.fetchAgGridData({
            sortBy: params.sortModel,
            filters,
            filtersMatch: componentParent.filtersMatch,
            skip: params.startRow,
            limit: componentParent.cacheBlockSize,
          });

          const rowsThisPage = data.data;
          let lastRow = -1;
          componentParent.count = data.count || 0;

          if (data.count) {
            if (data.count <= params.endRow) {
              lastRow = data.count;
            }
          } else if (rowsThisPage.length < componentParent.cacheBlockSize) {
            lastRow = params.endRow - componentParent.cacheBlockSize + rowsThisPage.length;
          }

          params.successCallback(rowsThisPage, lastRow);
          componentParent.$vs.loading.close(`${overlayId} > .con-vs-loading`);
          componentParent.gridApi.hideOverlay();

          if (componentParent.lastSavedId || componentParent.lastSavedIds.length > 0) {
            const nodes = [];

            if (componentParent.lastSavedId) {
              nodes.push(componentParent.gridApi.getRowNode(componentParent.lastSavedId));
            } else {
              componentParent.gridApi.forEachNode((node) => {
                if (componentParent.lastSavedIds.includes(node.id)) {
                  nodes.push(node);
                }
              });
            }
            componentParent.gridApi.flashCells({
              rowNodes: nodes,
              flashDelay: 3000,
              fadeDelay: 2000,
            });

            componentParent.lastSavedId = null;
            componentParent.lastSavedIds = [];
          }
        },
      },
      previousFiltersBulkOperation: false,
      setFiltersBulkOperation: false,
      setFiltersBulkOperationAmount: 0,
      setFiltersBulkOperationCount: 0,
      columnDefs: [],
      rowsSelected: [],
      selectedAllRows: false,
      getRowNodeId: null,
      searchQuery: '',
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        context: {
          componentParent: this,
        },
      },
      columnTypes: {
        dateTimeColumn: {
          minWidth: 260,
          maxWidth: 615,
          filter: 'agDateColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            alwaysShowBothConditions: false,
          },
          valueFormatter: (params) => this.$options.filters.date(params.value),
        },
        dateColumn: {
          minWidth: 260,
          maxWidth: 615,
          filter: 'agDateColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            alwaysShowBothConditions: false,
          },
          valueFormatter: (params) => this.$options.filters.date_format(
            params.value,
            'DD MMM YYYY',
            true,
            this.tenantAccountDetailsTimezone,
          ),
        },
      },
      defaultColDef: {
        floatingFilter: !this.readonlyList,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        unSortIcon: true,
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          alwaysShowBothConditions: true,
        },
      },
      commonColumnDefs: [
        {
          colId: 'creator',
          headerName: 'Creator',
          field: 'creator.id',
          filter: false,
          width: 0,
          hide: true,
        },
        {
          colId: 'createdAt',
          headerName: this.$t(this.createdAtHeaderName),
          field: 'createdAt',
          sort: 'desc',
          type: 'dateColumn',
        },
        {
          colId: 'updatedAt',
          headerName: this.$t(this.updatedAtHeaderName),
          field: 'updatedAt',
          type: 'dateColumn',
        },
        {
          colId: 'actions',
          minWidth: this.actionsMinWidth,
          maxWidth: this.actionsMaxWidth,
          sortable: false,
          filter: false,
          pinned: 'right',
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      lastSavedId: null,
      lastSavedIds: [],
      showOnlyFirstColumnOnMobile: true,
      paginationPageSize: 10,
    };
  },
  computed: {
    ...mapGetters({
      tenantAccountDetailsTimezone: 'auth/tenantAccountDetailsTimezone',
    }),
    totalPages() {
      if (this.gridApi) {
        return this.gridApi.paginationGetTotalPages() || 0;
      }
      return 0;
    },
    domLayout() {
      return 'normal';
    },
    gridStyle() {
      let height = '80vh';
      let minHeight = '400px';

      if (this.$mq === this.$enums.mqBreakpoints.MOBILE) {
        height = '60vh';
      } else if (this.count <= 1) {
        height = '40vh';
        minHeight = '250px';
      } else if (this.count <= 2) {
        height = '48vh';
        minHeight = '300px';
      } else if (this.count <= 3) {
        height = '56vh';
        minHeight = '300px';
      } else if (this.count <= 4) {
        height = '64vh';
        minHeight = '350px';
      } else if (this.count <= 5) {
        height = '72vh';
      }

      return { height, minHeight };
    },
    hasPagination() {
      return this.$mq !== this.$enums.mqBreakpoints.MOBILE;
    },
    suppressPaginationPanel() {
      return this.$mq === this.$enums.mqBreakpoints.MOBILE;
    },
    suppressColumnVirtualisation() {
      return this.$mq === this.$enums.mqBreakpoints.MOBILE;
    },
    getColumnsToExport() {
      return this.columnDefs.filter(
        (c) => !c.colId || ['creator', 'actions'].indexOf(c.colId) === -1,
      ).map((c) => ({
        title: c.headerName,
        field: c.field,
      }));
    },
    columnDefsToImport() {
      return this.columnDefs.slice(
        0, this.columnDefs.length - this.commonColumnDefs.length,
      ).filter((value) => !value.ignoreOnImport).map((value) => ({
        headerName: value.headerName,
        field: value.field,
        required: value.requireOnImport || false,
        requireIfNull: value.requireOnImportIfNull || null,
        validation: value.validationOnImport || null,
        validateValueOnImport: value.validateValueOnImport || null,
      }));
    },
    rowsSelectedCount() {
      return this.rowsSelected.length;
    },
    rowsSelectedCountWithAllSelection() {
      return this.selectedAllRows ? this.count : this.rowsSelectedCount;
    },
    isAnyRowsSelected() {
      return this.rowsSelectedCount > 0;
    },
    isAnyRowsSelectedOrAll() {
      return this.isAnyRowsSelected || this.selectedAllRows;
    },
    rowsSelectedAreReallyAll() {
      return this.rowsSelectedCount === this.count;
    },
    rowsSelectedWithAllSelectionOrNull() {
      return !this.selectedAllRows || this.rowsSelectedAreReallyAll
        ? this.rowsSelected : null;
    },
  },
  watch: {
    $mq(newVal, oldVal) {
      if ((newVal === this.$enums.mqBreakpoints.MOBILE
        || oldVal === this.$enums.mqBreakpoints.MOBILE)
        && newVal !== oldVal) {
        this.setSelectedRowsNone();
        this.agGridKey += 1;
        this.rowsSelected = [];
      }
    },
    filtersMatch() {
      this.resetDataSourceAndSelection();
      this.$emit('update:initial-filter-match', this.filtersMatch);
    },
  },
  created() {
    this.paginationPageSize = this.defaultPaginationPageSize;
  },
  beforeMount() {
    this.getRowNodeId = (data) => data.id;
    if (this.hideActionsColumn) {
      this.deleteCommonColumnDef('actions');
    }

    if (this.hideCreatedAtColumn || this.hideTimestampsColumns) {
      this.deleteCommonColumnDef('createdAt');
    }

    if (this.hideUpdatedAtColumn || this.hideTimestampsColumns) {
      this.deleteCommonColumnDef('updatedAt');
    }

    this.columnDefs = [...this.columnDefs, ...this.commonColumnDefs];
  },
  beforeDestroy() {
    this.gridApi.destroy();
  },
  methods: {
    async onGridReady(params) {
      this.loadGridApi();

      if (this.dataModel === this.$enums.DataModel.SERVER) {
        const overlayId = `#${this.agGridOverlayId}`;
        this.gridApi.showLoadingOverlay();
        this.$vs.loading({
          container: overlayId,
          scale: 0.6,
        });

        await this.setAgGridDataSource();
      }

      if (typeof this.initialFilters === 'object' && Object.keys(this.initialFilters).length > 0) {
        console.log('onGridReady', this.initialFilters);
        this.applyFilters(this.initialFilters);
      }
    },
    onModelUpdate(e) {
      this.fitColumnsOnChanges();

      if (this.selectedAllRows) {
        this.setSelectedRowsCurrentPage();
      }

      if (typeof this.afterOnModelUpdateFunction === 'function') {
        this.afterOnModelUpdateFunction();
      }
    },
    onFilterChanged() {
      this.filtersAppliedCount = Object.keys(this.gridApi.getFilterModel()).length;
      this.setSelectedRowsNone();
      this.$emit('update:initial-filters', this.getMappedFilterModel());
    },
    onPaginationChanged(e) {
      if (this.gridApi && e.api) {
        this.gridApi.paginationGoToPage(e.api.paginationGetCurrentPage());
        this.currentPage = e.api.paginationGetCurrentPage() + 1;
      }

      if (this.selectedAllRows) {
        this.setSelectedRowsAll();
      }
    },
    onFirstDataRendered(params) {
      this.fitColumnsOnChanges();
    },
    onGridSizeChanged(params) {
      this.fitColumnsOnChanges();
    },
    async setAgGridDataSource() {
      this.gridApi.setDatasource(this.dataSource);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      this.rowsSelected = this.gridApi.getSelectedRows();
      this.afterOnSelectionChanged();

      if (!this.currentPageIsSelected()) {
        this.selectionPageAlert = false;
      }
    },
    afterOnSelectionChanged() {},
    loadGridApi() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
        header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
      }
    },
    async fetchAgGridData() {
      return {
        data: [],
        count: 0,
      };
    },
    fitColumnsOnChanges() {
      if (this.$mq === this.$enums.mqBreakpoints.LAPTOP
          || this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
        this.columnDefs.forEach((col) => {
          this.gridColumnApi.setColumnVisible(col.colId, !col.hide && col.colId !== 'actions');
        });
        this.autoSizeOrFitColumnsByViewport();
        this.gridColumnApi.setColumnVisible('actions', true);
        this.gridColumnApi.autoSizeColumn('actions', true);
      } else if (this.$mq === this.$enums.mqBreakpoints.TABLET) {
        this.columnDefs.forEach((col) => {
          this.gridColumnApi.setColumnVisible(col.colId, !col.hide && col.colId !== 'actions');
        });
        this.autoSizeOrFitColumnsByViewport();
      } else {
        this.columnDefs.forEach((col, index) => {
          this.gridColumnApi.setColumnVisible(
            col.colId,
            index === 0 || !this.showOnlyFirstColumnOnMobile,
          );
        });
        this.gridApi.sizeColumnsToFit();
      }
    },
    remainsSpaceInViewport() {
      const $viewport = this.$el.getElementsByClassName('ag-header-viewport').item(0);
      const $container = this.$el.getElementsByClassName('ag-header-container').item(0);

      if ($viewport && $container) {
        return $container.offsetWidth < $viewport.offsetWidth;
      }

      return null;
    },
    autoSizeOrFitColumnsByViewport() {
      this.autoSizeColumns();

      if (this.remainsSpaceInViewport()) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    autoSizeColumns() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    },
    rowDataUpdated() {
      this.fitColumnsOnChanges();
    },
    async resetDataSourceAndSelection() {
      if (this.dataModel === this.$enums.DataModel.SERVER) {
        await this.setAgGridDataSource();
        this.setSelectedRowsNone();
      }
    },
    getMappedFilterModel() {
      const filters = { ...this.gridApi.getFilterModel() };

      Object.keys(filters).forEach((key) => {
        if (key === 'tags' || filters[key].filterType === this.$enums.AppFilterType.CATEGORIES) {
          filters[key].filter = filters[key].filter.map((value) => (typeof value === 'object' ? value.id : value));
        }
      });

      return filters;
    },
    getSortModel() {
      const colState = this.gridColumnApi.getColumnState();
      return colState.filter((s) => s.sort != null).map((s) => ({
        colId: s.colId,
        sort: s.sort,
      }));
    },
    applyFilters(filters) {
      if (filters && typeof filters === 'object') {
        const filtersToApply = this.getFiltersToApply(filters);
        this.setBulkOperation(filtersToApply);
        this.gridApi.setFilterModel(filtersToApply);
      }
    },
    getFiltersToApply(filters) {
      if (filters && typeof filters === 'object') {
        return Object.keys(filters).filter((key) => key !== '_id'
          && key !== 'id' && typeof filters[key] === 'object').reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = filters[key];
          return obj;
        }, {});
      }

      return {};
    },
    setBulkOperation(filtersToApply, minFiltersCount = 1) {
      const filtersCount = this.getFiltersBulkOperationAmount(filtersToApply);

      if (filtersCount > minFiltersCount) {
        this.setFiltersBulkOperation = true;
        this.previousFiltersBulkOperation = true;
        this.setFiltersBulkOperationAmount = filtersCount;
        this.setFiltersBulkOperationCount = 0;
      }
    },
    resetFilters() {
      const model = this.gridApi.getFilterModel();

      if (model && typeof model === 'object' && Object.keys(model).length > 0) {
        const filtersCount = this.getFiltersBulkOperationAmount(model);
        if (filtersCount > 1) {
          this.setFiltersBulkOperation = true;
          this.setFiltersBulkOperationAmount = filtersCount;
          this.setFiltersBulkOperationCount = 0;
        }
      }

      this.gridApi.setFilterModel(null);
    },
    getFiltersBulkOperationAmount(filters) {
      let count = 1;
      // eslint-disable-next-line no-restricted-syntax
      for (const value of Object.values(filters)) {
        // eslint-disable-next-line no-restricted-globals
        if (typeof value === 'object'
        && ([
          this.$enums.AppFilterType.TAGS,
          this.$enums.AppFilterType.BOOLEAN,
          this.$enums.AppFilterType.CATEGORIES,
        ].indexOf(value.filterType) !== -1
        || this.previousFiltersBulkOperation)) {
          count += 1;
        }
      }

      return count;
    },
    paginationSetPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.paginationPageSize = size;
      this.selectionPageAlert = false;
    },
    setSelectedRowsAll() {
      this.selectedAllRows = true;
      this.gridApi.forEachNode((node) => {
        node.setSelected(true);
      });
    },
    setSelectedRowsCurrentPage() {
      const currentPage = this.currentPage - 1;
      const startRow = currentPage * this.paginationPageSize;
      const endRow = startRow + this.paginationPageSize;

      this.gridApi.forEachNode((node) => {
        if (node.rowIndex >= startRow && node.rowIndex < endRow) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },
    currentPageIsSelected() {
      const currentPage = this.currentPage - 1;
      const startRow = currentPage * this.paginationPageSize;
      const endRow = startRow + this.paginationPageSize;
      let currentPageIsSelected = true;

      this.gridApi.forEachNode((node) => {
        if (node.rowIndex >= startRow && node.rowIndex < endRow) {
          if (!node.isSelected()) {
            currentPageIsSelected = false;
          }
        } else if (node.isSelected()) {
          currentPageIsSelected = false;
        }
      });

      return currentPageIsSelected;
    },
    setSelectedRowsNone() {
      this.selectedAllRows = false;
      this.gridApi.deselectAll();
    },
    selectFromHeader(selection, fromCheckbox = false) {
      switch (selection) {
        case 'all':
          this.setSelectedRowsAll();
          break;
        case 'none':
          this.setSelectedRowsNone();
          break;
        case 'page':
          this.selectedAllRows = false;
          this.gridApi.deselectAll();
          this.setSelectedRowsCurrentPage();
          this.selectionPageAlert = fromCheckbox && this.count > this.paginationPageSize;
          break;

        default:
      }
    },
    deleteColumnDef(coldId = '') {
      const index = this.columnDefs.findIndex((col) => col.colId === coldId);

      if (index !== -1) {
        this.columnDefs.splice(index, 1);
      }
    },
    deleteCommonColumnDef(coldId = '') {
      const index = this.commonColumnDefs.findIndex((col) => col.colId === coldId);

      if (index !== -1) {
        this.commonColumnDefs.splice(index, 1);
      }
    },
    refreshRowNodeDataById(id, data, flash = true) {
      const rowNode = this.gridApi.getRowNode(id);

      // eslint-disable-next-line no-empty
      if (rowNode) {
        rowNode.data = data;
        this.gridApi.refreshCells();

        if (flash) {
          this.gridApi.flashCells({
            rowNodes: [rowNode],
          });
        }
      }
    },
  },
};
